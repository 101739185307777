import React, { useState } from "react"
import BgImage from "./bgImage"
import Button from "./button"
import { GatsbyImage } from "gatsby-plugin-image"
import extractColourCombinationClassName from "./utils/extractColourCombination"
import { OpenModal, Modal } from "./modal"

export default function Details({ detail }) {
	const [zoom, setZoom] = useState(false)

	function zoomImage() {
		setZoom(true)
	}

	function unZoomImage() {
		setZoom(false)
	}

	const color = extractColourCombinationClassName(detail.colourCombination)
	return (
		<>
			<div className='pl20 pr20 max-1400 ma m-w100 relative'>
				<div className='relative br-20 m-ratio-2-1 ratio-5-1  justify-center flex align-center flex-col pos-rel overflow-hidden'>
					<div className='inner relative flex flex-col justify-center align-center m-ma yellow'>
						<h2 className='text-center mb20 m-mb15 m-text-center'>{detail.title}</h2>
						<OpenModal name={detail.popupSlug} className="">
							<Button onMouseEnter={zoomImage} onMouseLeave={unZoomImage} as='div' className="">
								{detail.buttonText}
							</Button>
						</OpenModal>
						<DetailsPopup detail={detail} color={color} />
					</div>
					<BgImage>
						<GatsbyImage alt='' className={"bg-image m-brightness-75  animate-zoom " + (zoom ? "zoomed-image" : "")} image={detail.featuredImage.gatsbyImageData} />
					</BgImage>
				</div>
			</div>
			{detail.detail?.map(detail => (
				<Modal key={detail.popupSlug} name={detail.popupSlug} color={color}>
					<h2 className='mb40  m-mb40'>The Details</h2>
					<div className='grid grid-cols-2 grid-rows-2 m-grid-cols-1   gap-y-70 m-gap-y-20 '>
						{detail.questions?.map((question, index) => {
							return (
								<div className='max-450 m-max-w-auto m-w100' key={index}>
									<h2 className='mb40'>{question.name}</h2>
									<p>{question.content}</p>
								</div>
							)
						})}
					</div>
				</Modal>
			))}
		</>
	)
}

function DetailsPopup({ detail, color }) {
	return (
		<Modal name={detail.popupSlug} color={color}>
			<div>
				<h2 className='mb40 m-mb40'>The Details</h2>
				<div className='grid grid-cols-3 grid-rows-2 m-grid-cols-1 gap-x-30 m-gap-y-20 gap-y-70'>
					{detail.detail?.map((detail, index) => (
						<Question key={index} detail={detail} />
					))}
				</div>
			</div>
		</Modal>
	)
}

function Question({ detail }) {
	return (
		<>
			<OpenModal name={detail.popupSlug}>
				<div className='aspect-4-3 relative'>
					<div className='p20 pl10 z-20 relative'>
						<h2 className='m0'>{detail.title}</h2>
					</div>
					<div className='br-10 overflow'>
						<BgImage>
							<GatsbyImage className='bg-image' alt='' image={detail.image?.gatsbyImageData} />
						</BgImage>
					</div>
				</div>
			</OpenModal>
		</>
	)
}
