import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"
import Button from "./button"
import { Modal, OpenModal } from "./modal"
import BgImage from "./bgImage"
import ConditionallyRender from "./utils/conditionallyRender"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import extractColourCombinationClassName from "./utils/extractColourCombination"

export default function Events({ eventCategories }) {
	return (
		<>
			<div className='grid grid-cols-2 gap-20 max-1400 ma mb20 pl20 pr20 m-grid-cols-1 m-gap-y-15 m-w100'>
				{eventCategories.map((event, index) => {
					const color = extractColourCombinationClassName(event.colourCombination)
					return (
						<>
							<OpenModal key={index} name={event.popupSlug} className='event-card cursor-pointer'>
								<div className='event-card'>
									<div className='overflow-hidden ratio-1-1 m-ratio-1-1 pos-rel br-20 flex flex-wrap'>
										<div className='w-100 mba pos-rel z-2 p20'>
											<h2>{event.title}</h2>
											<h2>{event.date}</h2>
										</div>
										<div className='w-100 mta pos-rel p20 flex z-2'>
											<Button as='div' className=''>{event.buttonText}</Button>
										</div>
										<div className={'overlay '+color}/>
										<GatsbyImage alt='' className='w-101 bg-image image-zoom' image={event.image?.gatsbyImageData} />
									</div>
								</div>
							</OpenModal>
							<Modal key={event.popupSlug} name={event.popupSlug} color={color}>
								<h2>{event.title}</h2>
								<h2 className='mb40 m-mb20'>{event.date}</h2>
								{(event.popupCaption ? <h2 className='mb40 m-mb20 max-650'>{event.popupCaption}</h2>:'')}
								<div className='grid grid-cols-3 grid-rows-2 gap-x-30 gap-y-70 m-grid-cols-1 m-gap-y-20 '>
									{event.event.map(item => (
										<>
											<OpenModal key={item.slug + ":"} name={item.slug} className='cursor-pointer aaa'>
												<div className='aspect-4-3 relative br-10 overflow'>
													<BgImage>
														<GatsbyImage alt='' className='h-100 w-100' image={item?.image?.gatsbyImageData} />
													</BgImage>
													<div className='z-20 relative p20 pl15 pr15'>
														<h2 className='h3 m0'>{item.title}</h2>
														<p className='h3 m0'>{item.time}</p>
													</div>
												</div>
											</OpenModal>
										</>
									))}
								</div>
							</Modal>
						</>
					)
				})}
			</div>
			{eventCategories.map(event => {
				const color = extractColourCombinationClassName(event.colourCombination)
				return (
					<React.Fragment key={event.popupSlug + "--secondary"}>
						{event.event.map(item => (
							<InnerModals item={item} color={color} />
						))}
					</React.Fragment>
				)
			})}
			
		</>
	)
}

function InnerModals({ item, color }) {
	useEffect(() => {
		const contentDivs = document.querySelectorAll(".event--content")
		contentDivs.forEach(div => {
			const links = div.querySelectorAll("p a")
			if (!links) return
			links.forEach(link => {
				link.classList.add("ul-link")
				link.target = "_blank"
				link.rel = "noopener noreferrer"
			})
		})
	}, [item, color])

	if (!item) return

	return (
		<Modal key={item.slug} name={item.slug} color={color}>
			<>
				<h2>{item.title}</h2>
				<h2 className='mb40'>{item.date}</h2>
				<div className='grid grid-cols-2 m-grid-cols-1 gap-x-10 '>
					<div>
						<div className='max-450 m-mb40'>
							<ReactMarkdown className='mb40 pr25 event--content'>{item.content}</ReactMarkdown>
							<ConditionallyRender when={item.buttonLink && item.buttonText}>
								<Button className='inherit' as={"a"} target='_blank' href={item.buttonLink}>
									{item.buttonText}
								</Button>
							</ConditionallyRender>
						</div>
					</div>
					<div className='relative'>
						<GatsbyImage alt='' className='w-100' image={item.eventImage.gatsbyImageData} />
					</div>
				</div>
			</>
		</Modal>
	)
}

export const query = graphql`
	fragment EventCategory on DatoCmsEventCategory {
		name
		image {
			gatsbyImageData
		}
	}
`
