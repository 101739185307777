import * as React from "react"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Events from "../components/events"
import Location from "../components/location"
import Details from "../components/details"
import { graphql } from "gatsby"
import resolveToEvents from "../utils/resolveToEvents"

const IndexPage = ({ data: { home, thursday, friday, saturday, detail, marketDay } }) => {
	console.log(thursday)
	return (
		<Layout>
			<Seo title='James St Food + Wine Trail 2024' />
			<Hero video={home.heroVideo} image={home.heroImage} mobile={home.mobileImage} bottomCaption={home.bottomCaption} duration={home.duration} bottomCaptionMobile={home.bottomCaptionMobile} />
			<Events eventCategories={resolveToEvents(thursday, friday)} />
			<Location marketDay={marketDay} />
			<Details detail={detail} />
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	query HomePageQuery {
		home: datoCmsHomepage {
			heroImage {
				gatsbyImageData
			}
			heroVideo
			mobileImage {
				gatsbyImageData
			}
			bottomCaption
			bottomCaptionMobile
			duration
		}
		marketDay: datoCmsSunday {
			title
			date
			buttonTextEvents
			buttonTextMap
			popupSlugEvents
			popupTitleMap
			popupTitleEvents
			mapIntroduction
			featureImage {
				gatsbyImageData
			}
			streetColourCombination
			venueColourCombination
			mapPopupSlug
			map {
				gatsbyImageData
			}
			mapMobile {
				gatsbyImageData
			}
			event {
				title
				time
				image: featuredImage {
					gatsbyImageData
				}
				eventImage {
					gatsbyImageData
				}
				slug
				content
				buttonText
				buttonLink
			}
			mapThumbnail {
				gatsbyImageData
			}
			mapTitle
			vendors {
				title
				time
				image: featuredImage {
					gatsbyImageData
				}
				eventImage {
					gatsbyImageData
				}
				slug
				content
				buttonText
				buttonLink
			}
		}
		thursday: datoCmsThursday {
			popupSlug
			buttonText
			popupCaption
			colourCombination
			image: featuredImage {
				gatsbyImageData
			}
			title
			date
			event {
				title
				time
				image: featuredImage {
					gatsbyImageData
				}
				eventImage {
					gatsbyImageData
				}
				slug
				content
				buttonText
				buttonLink
			}
		}
		friday: datoCmsFriday {
			popupSlug
			buttonText
			popupCaption
			colourCombination
			image: featuredImage {
				gatsbyImageData
			}
			title
			date
			event {
				title
				time
				image: featuredImage {
					gatsbyImageData
				}
				eventImage {
					gatsbyImageData
				}
				slug
				content
				buttonText
				buttonLink
			}
		}
		saturday: datoCmsSaturday {
			popupSlug
			buttonText
			colourCombination
			image: featuredImage {
				gatsbyImageData
			}
			title
			date
			event {
				title
				time
				image: featuredImage {
					gatsbyImageData
				}
				eventImage {
					gatsbyImageData
				}
				slug
				content
				buttonText
				buttonLink
			}
		}
		detail: datoCmsTheDetail {
			buttonText
			colourCombination
			featuredImage {
				gatsbyImageData
			}
			popupSlug
			title
			detail {
				title
				image: featuredImage {
					gatsbyImageData
				}
				questions: question {
					name
					content
				}
				popupSlug
			}
		}
	}
`
