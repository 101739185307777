import React from "react"
import Cross from "../icons/cross"
import { useContext } from "react"
import { ModalContext } from "./modalContext"

const OpenModal = ({ name, children, className = "cursor-pointer", key = "" }) => {
	const { openModal } = useContext(ModalContext)

	const handleOpenModal = e => {
		e.stopPropagation()
		openModal(name)
	}

	return (
		<div key={key} onClick={e => handleOpenModal(e)} role='presentation' className={`${className}`}>
			{children}
		</div>
	)
}

const Modal = ({ name, color, children }) => {
	const { closeModal, currentModal } = useContext(ModalContext)

	const handleCloseModal = () => {
		closeModal()
	}

	return (
		<>
			<div id='popup--overlay' className={`cursor-pointer ${currentModal === name ? "" : "n-active"}`} onClick={handleCloseModal}/>
			<div className={`popup--modal br-20 ${currentModal === name ? " " : " n-active"} p60 m-p20 m-pt60 ${color}`} id={name}>
				<div className='flex cross justify-end mt-20 mr-20 m-mt-40 m-mr-0 m-mb20'>
					<button onClick={handleCloseModal}>
						<Cross />
					</button>
				</div>
				{children}
			</div>
		</>
	)
}

export { OpenModal, Modal }
