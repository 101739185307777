import React from 'react'

export default function Cross() {
    return (
        <svg className='close--button' width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.5L22 21.5M21.5 1L1 21.5" stroke="black" />
        </svg>

    )
}
