import React from 'react'

export default function Button({ children, as = 'button', className, ...props }) {
    const As = as
    return (
        <As className={'text-center yellow explore-button ' + className}  {...props}>
            {children}
        </As>
    )
}
