import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
export default function Hero({ image, mobile, video, bottomCaption,bottomCaptionMobile, duration }) {
    return (
        <div className='max-1400 ma pl20 pr20 pb20'>
            <div className='ratio-2-1 m-ratio-1-2 overflow pos-rel br-20 overflow flex flex-wrap'>
                <div className='mba w-100 pos-rel z-2 p40 m-p20 m-mt20 text-center'>
                    <h2 className=''>{duration}</h2>
                </div>
                <div className='pos-rel z-2 mta text-center'>
                    <div className='p40 m-p20 '>
                        <div className='bottom--caption m-hide h2'>{bottomCaption}</div>
                        <div className='bottom--caption h2 m-show'>{bottomCaptionMobile}</div>
                    </div>
                </div>
                {(video ?
                    <video src={video} muted autoPlay playsInline loop className='bg-image' />:
                    <>
                        <GatsbyImage  className='bg-image m-show' image={mobile?.gatsbyImageData} alt='Hero Image' />
                        <GatsbyImage className='bg-image m-hide' image={image?.gatsbyImageData} alt='Hero Image' />
                    </>
                )}
            </div>
        </div>
    )
}