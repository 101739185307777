import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import BgImage from "./bgImage"
import Button from "./button"
import P from "./fonts"
import ConditionallyRender from "./utils/conditionallyRender"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import extractColourCombinationClassName from "./utils/extractColourCombination"
import { OpenModal, Modal } from "./modal"

export default function Location({ marketDay }) {
	const [zoom, setZoom] = useState(false)

	function zoomImage() {
		setZoom(true)
	}

	function unZoomImage() {
		setZoom(false)
	}
	const venueColor = extractColourCombinationClassName(marketDay.venueColourCombination)
	const streetColor = extractColourCombinationClassName(marketDay.streetColourCombination)
	if (!marketDay) return <></>
	return (
		<>
			<div className=' pl20 pr20 mb20 max-1400 ma'>
				<div className='flex flex-wrap aspect-2-1 m-ratio-1-1 br-20'>
						<div className='mta mba w-100 pos-rel z-2 p40 text-center'>
							<h2>{marketDay.title}</h2>
							<h2>{marketDay.date}</h2>
						</div>
						<div className='w-100 mta mba pos-rel z-2 flex justify-center'>
							<div className='p40 bg-beige br-20 text-center'>
								<p className='h2 mb20 mt0'>{marketDay.popupTitleMap}</p>
								<InVenue marketDay={marketDay} color={venueColor} zoomImage={zoomImage} unZoomImage={unZoomImage} /> 
								<OnTheStreet marketDay={marketDay} color={streetColor} zoomImage={zoomImage} unZoomImage={unZoomImage} />
							</div>					 
						</div>
					<div className='bg-image br-20 overflow'>
						<GatsbyImage alt='' className='bg-image image-zoom' image={marketDay.featureImage.gatsbyImageData} />
					</div>
				</div>
			</div>
		</>
	)
}

function InVenue({ marketDay, color, zoomImage, unZoomImage }) {
	return (
		<>
			<OpenModal name={marketDay.mapPopupSlug} className='relative z-20'>
				<Button className='uppercase z-1' onMouseEnter={zoomImage} onMouseLeave={unZoomImage} as='div'>
					<span>{marketDay.buttonTextMap}</span>
				</Button>
			</OpenModal>
			<Modal name={marketDay.mapPopupSlug} color={color}>
				<h2 className=''>{marketDay.mapTitle}</h2>
				<h2 className='mb40'>{marketDay.date}</h2>
				<div className='mb40 h3 max-450 ma'>{marketDay.mapIntroduction}</div>
				<GatsbyImage alt='' image={marketDay.map.gatsbyImageData} className="m-hide"/>
				<GatsbyImage alt='' image={marketDay.mapMobile.gatsbyImageData} className="m-show"/>
			</Modal>
		</>
	)
}

function OnTheStreet({ marketDay, color, zoomImage, unZoomImage }) {
	return (
		<>
			<OpenModal name={marketDay.popupSlugEvents}>
				<Button className='uppercase hide z-1' onMouseEnter={zoomImage} onMouseLeave={unZoomImage} as='div'>
					<span className='m-show'>{marketDay.popupTitleEvents}</span>
					<span className='m-hide'>{marketDay.buttonTextEvents}</span>
				</Button>
			</OpenModal>
			<Modal name={marketDay.popupSlugEvents} color={color}>
				<h2 className=''>{marketDay.popupTitleEvents}</h2>
				<h2 className='mb40'> {}</h2>
				<div className='grid grid-cols-3 grid-rows-2 gap-x-30 gap-y-70 m-gap-y-20 m-grid-cols-1'>
					{marketDay.event.map((event, index) => (
						<OpenModal key={event.slug + index} name={event.slug} className='cursor-pointer'>
							<div className='aspect-4-3 relative'>
								<BgImage>
									<GatsbyImage alt='' className='h-100 w-100' image={event?.image?.gatsbyImageData} />
								</BgImage>
								<div className='z-20 relative p20 pl15 pr15'>
									<h2 className='text-white uppercase text-left mb5 tracking-wide'>{event.title}</h2>
									<p className='text-white uppercase text-left m0 tracking-tight'>{event.time}</p>
								</div>
							</div>
						</OpenModal>
					))}
				</div>
			</Modal>
			{marketDay.event.map(event => (
				<InnerModals key={event.slug} item={event} color={color} />
			))}
		</>
	)
}

function InnerModals({ item, color }) {
	useEffect(() => {
		const contentDivs = document.querySelectorAll(".event--content")
		contentDivs.forEach(div => {
			const links = div.querySelectorAll("p a")
			if (!links) return
			links.forEach(link => {
				link.classList.add("ul-link")
				link.target = "_blank"
				link.rel = "noopener noreferrer"
			})
		})
	}, [item, color])

	if (!item) return

	return (
		<Modal name={item.slug} color={color}>
			<>
				<h2 className=''>{item.title}</h2>
				<h2 className=' mb40'> {item.date}</h2>
				<div className='grid grid-cols-2 m-grid-cols-1 gap-x-10 '>
					<div>
						<div className='max-450 m-mb40'>
							<ReactMarkdown className='mb40 pr25 event--content'>{item.content}</ReactMarkdown>
							<ConditionallyRender when={item.buttonLink && item.buttonText}>
								<Button className='inherit' as={"a"} target='_blank' href={item.buttonLink}>
									{item.buttonText}
								</Button>
							</ConditionallyRender>
						</div>
					</div>
					<div className='relative'>
						<GatsbyImage alt='' className='w-100' image={item.eventImage.gatsbyImageData} />
					</div>
				</div>
			</>
		</Modal>
	)
}
